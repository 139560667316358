import * as styleVars from '@common/styles/constants'

import { CSSProperties, FC, ReactNode } from 'react'
import { Interpolation, Theme } from '@emotion/react'
import { ToasterProps, useToaster } from 'react-hot-toast'

import SvgError from '@static/svg/icons/error.svg'
import SvgSuccess from '@static/svg/icons/success-circle.svg'
import SvgWarning from '@static/svg/icons/warning.svg'
import type { Toast as ToastType } from 'react-hot-toast'
import { classNames } from '@common/utils/helpers'
import styled from '@emotion/styled'
import { useIntl } from '@palqee/intl'
import { ToastCopyAction } from './actions/copy'

export interface IToastProps extends ToasterProps {
  className?: string | undefined
  containerCssProps?: Interpolation<Theme>
}

export interface TToasterProps extends Omit<ToastType, 'type'> {
  type?: 'success' | 'error' | 'info' | 'warning'
}

export type TToast = FC<IToastProps>

/**
 * @todo add width and height props instead of using containerCssProps
 *
 * @param props
 * @returns
 */
const Toast: TToast = (props: IToastProps) => {
  const { toasts, handlers } = useToaster()
  const {
    position: toastPosition,
    style,
    className: toastClassName,
  } = toasts?.[0] || {}
  const { className, position, containerCssProps } = props

  const intl = useIntl()
  const { startPause, endPause, calculateOffset, updateHeight } = handlers

  const classes = classNames('pq-toast', className || '', toastClassName || '')

  const renderDataByType = (type) => {
    switch (type) {
      case 'success':
        return {
          color: styleVars.gaugeSuccess,
          title: intl.formatMessage({
            id: 'SUCCESS',
            defaultMessage: 'Success',
          }),
          icon: <SvgSuccess height="32" width="32" />,
        }
      case 'error':
        return {
          color: styleVars.primaryRed,
          title: intl.formatMessage({ id: 'ERROR', defaultMessage: 'Error' }),
          icon: <SvgError />,
        }
      case 'info':
      case 'warning':
        return {
          color: styleVars.gaugeWarning,
          title: intl.formatMessage({
            id: 'warning',
            defaultMessage: 'warning',
          }),
          icon: <SvgWarning />,
        }
      case 'blank':
        return {}
    }
  }

  const setPosition = (): CSSProperties => {
    switch (toastPosition || position) {
      case 'bottom-left':
        return {
          position: 'fixed',
          bottom: 0,
          left: 0,
          marginLeft: '10px',
        }
      case 'bottom-right':
        return {
          position: 'fixed',
          bottom: 0,
          right: 0,
          marginRight: '10px',
        }
      case 'top-left':
        return {
          position: 'fixed',
          top: 0,
          left: 0,
          marginLeft: '10px',
        }
      case 'top-right':
        return {
          position: 'fixed',
          top: 0,
          right: 0,
          marginRight: '10px',
        }
      case 'top-center':
        return {
          top: 0,
          left: 0,
          right: 0,
          display: 'flex',
          position: 'absolute',
          justifyContent: 'center',
        }

      default:
        return {
          top: 0,
          left: 0,
          right: 0,
          display: 'flex',
          position: 'absolute',
          justifyContent: 'center',
        }
    }
  }

  return (
    toasts.length > 0 && (
      <ToastContainerWrapper
        style={setPosition()}
        onMouseEnter={startPause}
        onMouseLeave={endPause}
        css={containerCssProps}
      >
        {toasts.map((t) => {
          const { color, icon: Icon } = renderDataByType(t.type)

          const offset = calculateOffset(t, {
            reverseOrder: false,
            gutter: 20,
          })
          const ref = (el) => {
            if (el && !t.height) {
              const height =
                el.getBoundingClientRect().height === 0
                  ? '90px'
                  : el.getBoundingClientRect().height
              updateHeight(t.id, height)
            }
          }
          return (
            <ToastWrapper
              className={classes}
              visible={t.visible}
              offset={offset}
              key={t.id}
              ref={ref}
              style={{
                opacity: t.visible ? 1 : 0,
                ...style,
              }}
              data-cy="pq-toast"
              borderColor={color}
              {...t.ariaProps}
            >
              <ToastContentWrapper>
                <span>{Icon}</span>
                <ToastMessageWrapper color={color} className="pq-ml-2">
                  <span data-cy="pq-toast__message">
                    <>{t.message}</>
                  </span>
                </ToastMessageWrapper>
              </ToastContentWrapper>
            </ToastWrapper>
          )
        })}
      </ToastContainerWrapper>
    )
  )
}

const ToastContainerWrapper = styled.div`
  z-index: 9999;
  margin-bottom: 10px;
  height: auto;
`

const ToastWrapper = styled.div<{
  visible: boolean
  offset: number
  borderColor?: string
}>`
  position: absolute;
  display: flex;
  width: 100%;
  height: 100px;
  max-height: 100%;
  transition: all 0.5s ease-out;
  box-shadow: rgba(0, 0, 0, 0.2) 0px 18px 50px -10px;
  opacity: ${({ visible }) => (visible ? 1 : 0)};
  border-left: 8px solid ${({ borderColor }) => borderColor};
  border-radius: 3px;
  transform: ${({ offset }) => (offset ? `translateY(-${offset}px)` : '')};
`

const ToastContentWrapper = styled.div`
  display: flex;
  align-items: center;
  background: #fff;
  color: #363636;
  line-height: 1.3;
  width: 100%;
  will-change: transform;
  box-shadow:
    0 3px 10px rgb(0 0 0 / 10%),
    0 3px 3px rgb(0 0 0 / 5%);
  pointer-events: auto;
  padding: 12px 14px;
  border-radius: 3px;
  font-size: 14px;
`

const ToastMessageWrapper = styled.div<{ color: string }>`
  display: flex;
  flex-direction: column;
`

export interface IToastRenderProps {
  headerMessage?: ReactNode
  descriptionMessage?: ReactNode
  render?: () => ReactNode
  copyAction?: {
    url: string
  }
}

export const RenderToast = (toastRenderProps: IToastRenderProps) => {
  const { headerMessage, descriptionMessage, copyAction, render } =
    toastRenderProps

  return (
    <>
      {headerMessage && <h3 className="pq-mb-1">{headerMessage}</h3>}
      {descriptionMessage && (
        <span className="pq-mb-1">{descriptionMessage}</span>
      )}

      {copyAction && <ToastCopyAction {...copyAction} />}
      {render && render()}
    </>
  )
}

export default Toast
