import { useEffect, useId, useState } from 'react'
import { IUseClipboardReturn } from './types'

export const useClipboard = (): IUseClipboardReturn => {
  const clipboardId = useId()

  const [isCopying, setIsCopying] = useState({ [clipboardId]: false })
  const [isCopied, setIsCopied] = useState({ [clipboardId]: false })

  const runCopying = (id: string) => {
    setIsCopied((prev) => ({ ...prev, [id]: false }))
    setIsCopying((prev) => ({ ...prev, [id]: true }))
  }

  const runCopied = (id: string) => {
    setIsCopying((prev) => ({ ...prev, [id]: false }))
    setIsCopied((prev) => ({ ...prev, [id]: true }))
  }

  const clearClipboard = async (id: string) => {
    await navigator.clipboard.writeText('')
    setIsCopied((prev) => ({ ...prev, [id]: false })) // Optionally reset the copied state for the specific ID
  }

  const copy =
    (
      id: string,
      value?: string | { shouldFocus?: boolean },
      props?: { shouldFocus?: boolean },
    ) =>
    async (e) => {
      let localId = undefined
      let localValue: string | { shouldFocus?: boolean }
      let localProps: string | { shouldFocus?: boolean }

      if (typeof value === 'string') {
        localId = id
        localValue = value

        localProps = props
      } else {
        localId = clipboardId
        localValue = id
        localProps = value
      }

      e.preventDefault()

      if (!navigator?.clipboard) {
        console.warn('Clipboard not supported')
        return false
      }

      const { shouldFocus = false } = localProps ?? {}

      try {
        runCopying(localId)
        await navigator.clipboard.writeText(localValue)
        if (shouldFocus) e.target.focus()
        runCopied(localId)
        return true
      } catch (error) {
        console.error('Error copying to clipboard:', error)
        return false
      }
    }

  useEffect(() => {
    const timeouts = Object.keys(isCopied)

    timeouts.forEach((key) => {
      if (isCopied[key]) {
        setTimeout(() => {
          setIsCopied((prev) => ({ ...prev, [key]: false }))
        }, 5800)
      }
    })

    return () => {
      timeouts.forEach((id) => clearTimeout(id))
    }
  }, [isCopied])

  return {
    get: {
      isCopying,
      isCopied,
      clipboardId,
    },
    handlers: {
      copy,
      clearClipboard,
    },
  }
}
