import * as styleVars from '@common/styles/constants'
import { useClipboard } from '@common/hooks/use-clipboard'
import styled from '@emotion/styled'
import { useIntl } from '@palqee/intl'

const ToastCopyAction = (props: { url: string }) => {
  const { url } = props
  const intl = useIntl()

  const {
    handlers: { copy },
    get: { clipboardId, isCopied },
  } = useClipboard()

  const renderCopyAction = () => {
    const renderLabel = () => {
      const label = isCopied[clipboardId]
        ? intl.formatMessage({
            id: 'consent.form.submit.success.link_copied',
            defaultMessage: 'Copied',
          })
        : intl.formatMessage({
            id: 'consent.form.submit.success.link',
            defaultMessage: 'Copy Link',
          })

      return label
    }
    return (
      <CopyLinkWrapper
        onClick={(e) => {
          copy(url)(e)
        }}
      >
        {renderLabel()}
      </CopyLinkWrapper>
    )
  }

  return <div className="pq-mt-1">{renderCopyAction()}</div>
}

const CopyLinkWrapper = styled.div`
  text-decoration: underline;
  font-size: 0.75rem;
  font-weight: 600;
  color: ${styleVars.primaryBlue};
  cursor: pointer;
`
export { ToastCopyAction }
